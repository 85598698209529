import { Fragment } from 'react';
import { useIntervalCheckMe } from '~/hooks/useIntervalCheckMe';
import { useElementAsComponent } from '~/pages/heineken_template/_private/useElementAsComponent';
/** 定時詢問 api GET me 查詢權限是否具備，並在 UI 層級，呈現達到自動登出的機制 */
export function TemplateAutoCheckMeProvider(props) {
    const LoginPage = useElementAsComponent(props.templateProps.layout.login);
    const hasLogin = useIntervalCheckMe({
        permissionCheckFn() {
            return props.templateProps.permissions.pageview.check();
        },
        interval: 5 * /*分鐘*/ 1000 * 60,
    });
    if (hasLogin) {
        return <Fragment>{props.children}</Fragment>;
    }
    return <LoginPage />;
}
