import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Button, CopyButton } from '@mantine/core';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fontWeight500 } from '~/css/font';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_vertical_all_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { redirectStore, } from '~/pages/daddy960_opkevin/component/Daddy960FreeTrialButton';
import { fadeIn } from './OptionChart';
import { useDaddy960Trial } from './useDaddy960Tutorial';
import { ProductKey } from '~/pages/win988/component/ProductKey';
const GuidanceTtips = memo(function GuidanceTtips(props) {
    const url = props.url;
    const containerCss = css `
    ${fill_vertical_all_center};
    ${jc.spaceBetween};
    width: 90%;
    height: 88px;
    background-color: #5d7c9daa;
    border-radius: 5px;
    padding: 8px;
  `;
    const textCss = css `
    ${fill_vertical_all_center};
    height: 40px;
    width: 240px;
    font-size: 12px;
  `;
    return (<div css={containerCss}>
      <span css={textCss}>如被封鎖存取權，請點擊複製網址按鈕至其他瀏覽器貼上繼續使用頁面。</span>
      <CopyButton value={url}>
        {({ copied, copy }) => (<Button color={copied ? 'teal' : 'blue'} onClick={copy} radius='xs' size='xs' compact>
            {copied ? '已複製網址' : '複製網址'}
          </Button>)}
      </CopyButton>
    </div>);
});
const GoogleLoginView = memo(function GoogleLoginView() {
    const auth = useFirebaseMeState.useContainer();
    const router = useRouter();
    return (<googleButtonStyled.button onClick={() => {
            auth.acts.loginWithGoogle().then(() => {
                router.reload();
            });
        }}>
      <googleButtonStyled.logo src='google-icon.png'/>
      會員登入 / 開始試用
    </googleButtonStyled.button>);
});
export const UnregisteredView = memo(function UnregisteredView(props) {
    const firebaseMe = useFirebaseMeState.useContainer();
    const meUserState = useMeStore(state => state.meUserState);
    const trial = useDaddy960Trial(props.product);
    const activationCode = props.activationCode;
    /** 會員名稱 */
    const userName = meUserState?.name ?? '-';
    /** 會員代碼 */
    const userNumber = useMeStore(state => state.meUserState?.code);
    /** 會員信箱 */
    const email = useMeStore(state => state.meUserState?.email);
    const photoUrl = useMeStore(state => state.meUserState?.photo);
    /** 會員大頭貼 */
    const userPicture = (<Avatar src={photoUrl} size={72} radius='lg' alt="it's me"/>);
    /** 當送出申請試用時 reload page */
    const redirected = useSnapshot(redirectStore).redirect;
    /** 未過期 && 已不是新用戶 */
    // useEffect(() => {
    //   if (redirected === true && trial.state.newUser === false && trial.state.expire === false)
    //     return window.location.reload()
    // }, [redirected, trial.state.expire, trial.state.newUser])
    return (<div css={css `
        ${fill_vertical_all_center};
        ${jc.spaceEvenly};

        padding: 2px;
      `}>
      {userPicture}
      <infoStyleds.item>{userName}</infoStyleds.item>
      <infoStyleds.itemTitle>會員代碼</infoStyleds.itemTitle>
      <infoStyleds.item>{userNumber}</infoStyleds.item>
      <infoStyleds.itemTitle>Email信箱</infoStyleds.itemTitle>
      <infoStyleds.item>{email}</infoStyleds.item>

      <infoStyleds.toolTipContent>
        <p>權限尚未開通</p>
        <a href={props.lineLink} target='_blank' rel='noreferrer' css={css `
            color: #ffff00;
          `}>
          {'>>'}試用請點擊此處聯繫客服{'<<'}
        </a>
      </infoStyleds.toolTipContent>

      {activationCode === true && <ProductKey />}

      {/* 這邊是當用戶申請試用後的跳轉期間 避免客戶看到已無試用權限 多新增了跳轉過渡期動畫 */}
      {/* {trial.state.newUser === true ? (
          <Daddy960FreeTrialButton />
        ) : redirected === false ? (
          <infoStyleds.messageContent>
            <span>您已無試用權限</span>
            <a
              href={'https://960.oen.tw/?t=1681136671759'}
              target={'_blank'}
              rel='noreferrer'
              css={css`
                padding: 2px;
                color: #ffffff;
                background-image: linear-gradient(to right, #8f41e9, #578aef);
                border-radius: 2px;
                line-height: 18px;
                margin-left: 8px;
                transition: 0.3s;
                border: 1px solid #cccccc;
                &:hover {
                  background-image: linear-gradient(to left, #cf41e9, #f78aef);
                  text-decoration: underline;
                }
              `}
            >
              點我去抖內
            </a>
          </infoStyleds.messageContent>
        ) : (
          <infoStyleds.messageContent>
            試用資格申請中 &nbsp;
            <span css={loader} />
          </infoStyleds.messageContent>
        )} */}

      <infoStyleds.logoutButton onClick={() => firebaseMe.acts.logout()}>
        登出
      </infoStyleds.logoutButton>
    </div>);
});
export const Daddy960LoginView = memo(function Daddy960LoginView(props) {
    const meFirebaseState = useMeStore(state => state.meFirebaseState);
    const activationCode = props.activationCode ?? false;
    const { isPhone, isPc } = useMedia();
    return (<styleds.container>
      <styleds.lineButton css={css `
          right: ${!isPhone ? '16px' : '2%'};
          bottom: ${!isPhone ? '2%' : '0'};
        `}>
        <a href={props.lineLink} target={'_blank'} rel='noreferrer'>
          <img src='/futuresai/index/line.png' css={css `
              width: 64px;
            `}/>
        </a>
        {!isPhone && '聯繫客服'}
      </styleds.lineButton>

      {!meFirebaseState && (<styleds.loginCard>
          <styleds.logo src={props.faviconUrl}/>
          <GoogleLoginView />
          <p css={css `
              margin: 0px;
            `}>
            請使用Google帳號登入
          </p>
          {!isPc && <GuidanceTtips url={props.productUrl}/>}
        </styleds.loginCard>)}

      {meFirebaseState && (<styleds.loginCard>
          <UnregisteredView product={props.product} lineLink={props.lineLink} activationCode={activationCode}/>
        </styleds.loginCard>)}
    </styleds.container>);
});
const googleButtonStyled = {
    button: styled.div `
    ${fill_horizontal_all_center};
    ${fontWeight500};
    padding: 0px 16px;
    width: 240px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #aaaaaa;
    color: #7b7b7b;
    gap: 4px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #f1f1f1;
      width: 248px;
      height: 40px;
      font-size: 16.8px;
      transition: 0.3s;
    }
  `,
    logo: styled.img `
    width: 32px;
  `,
};
const infoStyleds = {
    itemTitle: styled.p `
    ${fill_vertical_all_center};
    width: 120px;
    height: 32px;
    background-color: #5d7c9daa;
    border-radius: 5px;
    ${fontWeight500};
    margin: 0px;
  `,
    item: styled.p `
    ${fill_vertical_all_center};
    height: 30px;
    font-size: 16px;
    ${fontWeight500};
    margin: 4px;
  `,
    logoutButton: styled.div `
    ${fill_vertical_all_center};
    width: 260px;
    height: 32px;
    background-color: #ca4b4b;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #da5b5b;
    }
  `,
    toolTipContent: styled.div `
    ${fill_vertical_all_center};
    height: 72px;
    width: 260px;
    background-color: #ca4b4b66;
    border-radius: 5px;

    > p {
      margin: 0px;
    }
  `,
    messageContent: styled.span `
    ${fill_horizontal_all_center};
    height: 30px;
  `,
};
const styleds = {
    container: styled.div `
    position: fixed;
    z-index: 1000;
    ${fill_vertical_all_center};
    background-image: linear-gradient(90deg, #ffffff11, #ffffff11),
      url('opkevin/background-default.png');
    background-size: cover;
    padding: 8px;
  `,
    loginCard: styled.div `
    ${fill_vertical_all_center};
    width: 336px;
    height: 560px;
    background: linear-gradient(85deg, #ffffff33, #ffffff55);
    border-radius: 16px;
    animation: ${fadeIn} 2s;
    gap: 40px;
  `,
    lineButton: styled.div `
    display: flex;
    position: fixed;
    flex-direction: column;
    z-index: 2;
  `,
    logo: styled.img `
    width: 196px;
    padding: 16px;
    border-radius: 5px;
    background-color: #5d7c9d55;
  `,
};
const loader = css `
  width: 22px;
  height: 22px;
  display: inline-block;
  position: relative;

  &:after,
  &:before {
    content: '';
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }
  &:after {
    animation-delay: 1s;
  }

  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
`;
